import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Tooltip } from '.';
import { Box } from '../Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <p>{`The Tooltip is used to show extra information on hover. Use sparingly as it onluy works on desktop, since mobile devices have no hover state.`}</p>
    <p>{`Tooltip is based on `}<a parentName="p" {...{
        "href": "https://github.com/wwayne/react-tooltip"
      }}>{`react-tooltip`}</a>{`. Check out the `}<a parentName="p" {...{
        "href": "https://react-tooltip.netlify.com/"
      }}>{`react-tooltip docs`}</a>{` for more usage examples.
Don't use the `}<inlineCode parentName="p">{`react-tooltip`}</inlineCode>{` prop `}<inlineCode parentName="p">{`type`}</inlineCode>{`. Instead use `}<inlineCode parentName="p">{`context`}</inlineCode>{` as you would for a `}<a parentName="p" {...{
        "href": "/components/Button"
      }}>{`Button`}</a></p>
    <p>{`Use `}<inlineCode parentName="p">{`data-tip`}</inlineCode>{` attribute on any element to attach a `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` to it.
Then add `}<inlineCode parentName="p">{`data-for="someid"`}</inlineCode>{` to the same element.
Finally give the `}<inlineCode parentName="p">{`<Tooltip id='someid'>`}</inlineCode>{` to link the two.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Tooltip} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={1} __code={'{/* Boxes are just for layout */}\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"default\">\n    {\' \'}\n    d(`･∀･)b{\' \'}\n  </a>\n  <Tooltip id=\"default\">Default is dark</Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"context\">\n    {\' \'}\n    ( •̀д•́){\' \'}\n  </a>\n  <Tooltip id=\"context\" context=\"whiteout\">\n    Make it light with whiteout\n  </Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"anotherContext\">\n    {\' \'}\n    (･ω´･ )́){\' \'}\n  </a>\n  <Tooltip id=\"anotherContext\" context=\"host\">\n    Or any other color context\n  </Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"solid-dont-move\">\n    {\' \'}\n    (❂‿❂){\' \'}\n  </a>\n  <Tooltip id=\"solid-dont-move\" effect=\"solid\">\n    Don\'t move\n  </Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"place-right\">\n    {\' \'}\n    இдஇ{\' \'}\n  </a>\n  <Tooltip id=\"place-right\" place=\"right\">\n    Don\'t move\n  </Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"place-bottom\">\n    {\' \'}\n    (〃∀〃){\' \'}\n  </a>\n  <Tooltip id=\"place-bottom\" place=\"bottom\">\n    Stick to the bottom\n  </Tooltip>\n</Box>\n<Box padding=\"xlarge\" paddingLeft=\"xlarge\">\n  <a data-tip data-for=\"solid-left\">\n    {\' \'}\n    ¯\\_(ツ)_/¯{\' \'}\n  </a>\n  <Tooltip id=\"solid-left\" effect=\"solid\" place=\"left\">\n    Don\'t move, stay left\n  </Tooltip>\n</Box>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Tooltip,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {
        /* Boxes are just for layout */
      }
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='default'> d(`･∀･)b </a>
        <Tooltip id='default' mdxType="Tooltip">
  Default is dark
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='context'> ( •̀д•́) </a>
        <Tooltip id='context' context='whiteout' mdxType="Tooltip">
  Make it light with whiteout
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='anotherContext'> (･ω´･ )́) </a>
        <Tooltip id='anotherContext' context='host' mdxType="Tooltip">
  Or any other color context
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='solid-dont-move'> (❂‿❂) </a>
        <Tooltip id='solid-dont-move' effect='solid' mdxType="Tooltip">
  Don't move
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='place-right'> இдஇ </a>
        <Tooltip id='place-right' place='right' mdxType="Tooltip">
  Don't move
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='place-bottom'> (〃∀〃) </a>
        <Tooltip id='place-bottom' place='bottom' mdxType="Tooltip">
  Stick to the bottom
        </Tooltip>
  </Box>
  <Box padding='xlarge' paddingLeft="xlarge" mdxType="Box">
        <a data-tip data-for='solid-left'> ¯\_(ツ)_/¯ </a>
        <Tooltip id='solid-left' effect='solid' place='left' mdxType="Tooltip">
  Don't move, stay left
        </Tooltip>
  </Box>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      